import { render, staticRenderFns } from "./BaseRadio.vue?vue&type=template&id=158da514"
import script from "./BaseRadio.vue?vue&type=script&lang=js"
export * from "./BaseRadio.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('158da514')) {
      api.createRecord('158da514', component.options)
    } else {
      api.reload('158da514', component.options)
    }
    module.hot.accept("./BaseRadio.vue?vue&type=template&id=158da514", function () {
      api.rerender('158da514', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Inputs/BaseRadio.vue"
export default component.exports