<template>
  <div>
    <el-dialog title="" :visible.sync="centerDialogVisiblec" width="86%" center>
      <template slot="title">
        <h2 class="text-lgs text-left text-black font-semibold">
          Cookie Policy of youthforindia.org
        </h2>
      </template>
      <div class="">
        <p class="text-gray font--normal fsize14">
          This document informs Users about the technologies that help
          youthforindia.org to achieve the purposes described below. Such
          technologies allow the Owner to access and store information (for
          example by using a Cookie) or use resources (for example by running a
          script) on a User’s device as they interact with youthforindia.org.<br />
          For simplicity, all such technologies are defined as "Trackers" within
          this document – unless there is a reason to differentiate.
          <br />For example, while Cookies can be used on both web and mobile
          browsers, it would be inaccurate to talk about Cookies in the context
          of mobile apps as they are a browser-based Tracker. For this reason,
          within this document, the term Cookies is only used where it is
          specifically meant to indicate that particular type of Tracker.

          <br />Some of the purposes for which Trackers are used may also
          require the User's consent, depending on the applicable law. Whenever
          consent is given, it can be freely withdrawn at any time following the
          instructions provided in this document. <br />Youthforindia.org uses
          Trackers managed directly by the Owner (so-called “first-party”
          Trackers) and Trackers that enable services provided by a third-party
          (so-called “third-party” Trackers). Unless otherwise specified within
          this document, third-party providers may access the Trackers managed
          by them. The validity and expiration periods of Cookies and other
          similar Trackers may vary depending on the lifetime set by the Owner
          or the relevant provider. Some of them expire upon termination of the
          User’s browsing session. In addition to what’s specified in the
          descriptions within each of the categories below, Users may find more
          precise and updated information regarding lifetime specification as
          well as any other relevant information – such as the presence of other
          Trackers - in the linked privacy policies of the respective
          third-party providers or by contacting the Owner.
        </p>
        <div class="mt-3">
          <h2 class="text-lgs text-dark">
            Activities strictly necessary for the operation of youthforindia.org
            and delivery of the Service
          </h2>
          <p class="text-gray font-normal fsize14">
            Youthforindia.org uses so-called “technical” Cookies and other
            similar Trackers to carry out activities that are strictly necessary
            for the operation or delivery of the <br />Service.
          </p>
          <h6 class="text-sm my-1 text-gray font-semibold">
            Third-party Trackers
          </h6>
          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            accordion
            class="mt-2"
          >
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                  SPAM protection
                </h2></template
              >
              <div class="lg:pt-7 pt-4">
                <p class="text-787878 px-2">
                  This type of service analyzes the traffic of
                  youthforindia.org, potentially containing Users' Personal
                  Data, with the purpose of filtering it from parts of traffic,
                  messages and content that are recognized as SPAM.
                </p>
                <h2 class="font-semibold text-black text-lgs mt-3">
                  Google reCAPTCHA (Google LLC)
                </h2>
                <p class="text-787878 px-2 mt-2">
                  Google reCAPTCHA is a SPAM protection service provided by
                  Google LLC.<br />
                  The use of reCAPTCHA is subject to the Google privacy policy
                  and terms of use.<br />
                  <br />
                  Personal Data processed: Cookies and Usage Data.
                  <br />
                  <br />
                  Place of processing: United States – Privacy Policy.
                </p>
                <p class="text-787878 px-2 mt-2">Storage duration:</p>
                <ul>
                  <li class="lig">_GRECAPTCHA: duration of the session</li>
                  <li class="lig">rc::a: indefinite</li>
                  <li class="lig">rc::b: duration of the session</li>
                  <li class="lig">rc::c: duration of the session</li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="mt-3">
          <h2 class="text-lgs text-dark">
            Other activities involving the use of Trackers
          </h2>
          <div>
            <h6 class="text-sm my-1 text-gray font-semibold">
              Basic interactions & functionalities
            </h6>
            <p class="text-gray font-normal fsize14">
              Youthforindia.org uses Trackers to enable basic interactions and
              functionalities, allowing Users to access selected features of the
              Service and facilitating the User's communication with the Owner.
            </p>

            <el-collapse
              v-model="activeNames"
              @change="handleChange"
              accordion
              class="mt-3"
            >
              <el-collapse-item title="" name="1">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Interaction with live chat platforms
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <p class="text-787878 px-2">
                    This type of service allows Users to interact with
                    third-party live chat platforms directly from the pages of
                    youthforindia.org, for contacting and being contacted by
                    youthforindia.org support service. If one of these services
                    is installed, it may collect browsing and Usage Data in the
                    pages where it is installed, even if the Users do not
                    actively use the service. Moreover, live chat conversations
                    may be logged.
                  </p>

                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Facebook Messenger Customer Chat (Facebook, Inc.)
                  </h2>
                  <p class="text-787878 px-2 mt-1">
                    The Facebook Messenger Customer Chat is a service for
                    interacting with the Facebook Messenger live chat platform
                    provided by Facebook, Inc.
                    <br />
                    Personal Data processed: Cookies, Data communicated while
                    using the service and Usage Data.
                    <br />
                    Place of processing: United States – Privacy Policy.
                  </p>

                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Storage duration:
                  </h2>
                  <ul>
                    <li class="lig">_fbp: 3 months</li>
                  </ul>

                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Freshchat Widget (Freshworks, Inc.)
                  </h2>
                  <p class="text-787878 px-2 mt-1">
                    The Freshchat Widget is a service for interacting with the
                    Freshchat live chat platform provided by Freshworks, Inc.

                    <br />
                    Personal Data processed: Data communicated while using the
                    service, email address, Tracker and Usage Data.

                    <br />
                    Place of processing: United States – Privacy Policy.
                  </p>
                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Storage duration:
                  </h2>
                  <ul>
                    <li class="lig">_fw_crm_v: 1 year</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="mt-4">
            <h6 class="text-sm my-1 text-gray font-semibold">
              Experience enhancement
            </h6>
            <p class="text-gray font-normal fsize14">
              Youthforindia.org uses Trackers to provide a personalized user
              experience by improving the quality of preference management
              options, and by enabling interaction with external networks and
              platforms.
            </p>

            <el-collapse
              v-model="activeNames"
              @change="handleChange"
              accordion
              class="mt-3"
            >
              <el-collapse-item title="" name="1">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Interaction with external social networks and platforms
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <p class="text-787878 px-2">
                    This type of service allows interaction with social networks
                    or other external platforms directly from the pages of
                    youthforindia.org.
                    <br />The interaction and information obtained through
                    youthforindia.org are always subject to the User’s privacy
                    settings for each social network. <br />This type of service
                    might still collect traffic data for the pages where the
                    service is installed, even when Users do not use it.
                    <br />It is recommended to log out from the respective
                    services in order to make sure that the processed data on
                    youthforindia.org isn’t being connected back to the User’s
                    profile.
                  </p>
                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Facebook Like button and social widgets (Facebook, Inc.)
                  </h2>
                  <p class="text-787878 px-2 mt-1">
                    The Facebook Like button and social widgets are services
                    allowing interaction with the Facebook social network
                    provided by Facebook, Inc.

                    <br />
                    Personal Data processed: Cookies and Usage Data.

                    <br />
                    Place of processing: United States – Privacy Policy.
                  </p>

                  <h2 class="font-semibold text-black text-lgs mt-3">
                    Storage duration:
                  </h2>
                  <ul>
                    <li class="lig">_fbp: 3 months</li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="mt-4">
            <h6 class="text-sm my-1 text-gray font-semibold">Measurement</h6>
            <p class="text-gray font-normal fsize14">
              Youthforindia.org uses Trackers to measure traffic and analyze
              User behavior with the goal of improving the Service.
            </p>

            <el-collapse
              v-model="activeNames"
              @change="handleChange"
              accordion
              class="mt-3"
            >
              <el-collapse-item title="" name="1">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Analytics
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <p class="text-787878 px-2">
                    The services contained in this section enable the Owner to
                    monitor and analyze web traffic and can be used to keep
                    track of User behavior.
                  </p>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Google Analytics (Google LLC)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      Google Analytics is a web analysis service provided by
                      Google LLC (“Google”). Google utilizes the Data collected
                      to track and examine the use of youthforindia.org, to
                      prepare reports on its activities and share them with
                      other Google services.

                      <br />
                      Google may use the Data collected to contextualize and
                      personalize the ads of its own advertising network.

                      <br />
                      Personal Data processed: Cookies and Usage Data.
                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">AMP_TOKEN: 1 hour</li>
                      <li class="lig">_ga: 2 years</li>
                      <li class="lig">_gac*: 3 months</li>
                      <li class="lig">_gat: 1 minute</li>
                      <li class="lig">_gid: 1 day</li>
                    </ul>
                  </div>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Hotjar Form Analysis & Conversion Funnels (Hotjar Ltd.)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      Hotjar is an analytics service provided by Hotjar Ltd.

                      <br />
                      Hotjar honors generic Do Not Track headers. This means
                      your browser can tell its script not to collect any of
                      your data. This is a setting that is available in all
                      major browsers. Find Hotjar’s opt-out information here.

                      <br />
                      Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: Malta – Privacy Policy – Opt Out.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">
                        _hjAbsoluteSessionInProgress: 30 minutes
                      </li>
                      <li class="lig">
                        _hjCachedUserAttributes: duration of the session
                      </li>
                      <li class="lig">_hjClosedSurveyInvites: 1 year</li>
                      <li class="lig">_hjDonePolls: 1 year</li>
                      <li class="lig">_hjFirstSeen: duration of the session</li>

                      <li class="lig">
                        _hjIncludedInPageviewSample: 30 minutes
                      </li>
                      <li class="lig">_hjIncludedInSessionSample: 30 minute</li>
                      <li class="lig">
                        _hjLocalStorageTest: duration of the session
                      </li>

                      <li class="lig">_hjMinimizedPolls: 1 year</li>
                      <li class="lig">
                        _hjRecordingEnabled: duration of the session
                      </li>
                      <li class="lig">
                        _hjRecordingLastActivity: duration of the session
                      </li>

                      <li class="lig">_hjSession*: 30 minutes</li>
                      <li class="lig">
                        _hjSessionRejected: duration of the session
                      </li>
                      <li class="lig">
                        _hjSessionResumed: duration of the session
                      </li>

                      <li class="lig">
                        _hjSessionTooLarge: duration of the session
                      </li>
                      <li class="lig">_hjSessionUser*: 1 year</li>
                      <li class="lig">_hjShownFeedbackMessage: 1 year</li>

                      <li class="lig">_hjTLDTest: duration of the session</li>
                      <li class="lig">
                        _hjUserAttributesHash: duration of the session
                      </li>
                      <li class="lig">
                        _hjViewportId: duration of the session
                      </li>
                      <li class="lig">_hjid: 1 year</li>
                    </ul>
                  </div>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      LinkedIn conversion tracking (LinkedIn Corporation)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      LinkedIn conversion tracking is an analytics service
                      provided by LinkedIn Corporation that connects data from
                      the LinkedIn advertising network with actions performed on
                      youthforindia.org.

                      <br />
                      Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">
                        _hjAbsoluteSessionInProgress: 30 minutes
                      </li>
                      <li class="lig">
                        _hjCachedUserAttributes: duration of the session
                      </li>
                      <li class="lig">AnalyticsSyncHistory: 1 month</li>
                      <li class="lig">JSESSIONID: duration of the session</li>
                      <li class="lig">UserMatchHistory: 1 month</li>

                      <li class="lig">bcookie: 1 year</li>
                      <li class="lig">bscookie: 1 year</li>
                      <li class="lig">lang: duration of the session</li>

                      <li class="lig">lidc: 1 day</li>
                      <li class="lig">lms_ads: 1 month</li>
                      <li class="lig">lms_analytics: 1 month</li>
                    </ul>
                  </div>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Facebook Ads conversion tracking (Facebook pixel)
                      (Facebook, Inc.)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      Facebook Ads conversion tracking (Facebook pixel) is an
                      analytics service provided by Facebook, Inc. that connects
                      data from the Facebook advertising network with actions
                      performed on youthforindia.org. The Facebook pixel tracks
                      conversions that can be attributed to ads on Facebook,
                      Instagram and Audience Network.

                      <br />
                      Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">_fbp: 3 months</li>
                      <li class="lig">fr: 3 months</li>
                    </ul>
                  </div>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Twitter Ads conversion tracking (Twitter, Inc.)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      Twitter Ads conversion tracking is an analytics service
                      provided by Twitter, Inc. that connects data from the
                      Twitter advertising network with actions performed on
                      youthforindia.org.

                      <br />
                      Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">personalization_id: 2 years</li>
                    </ul>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="mt-4">
            <h6 class="text-sm my-1 text-gray font-semibold">
              Targeting & Advertising
            </h6>
            <p class="text-gray font-normal fsize14">
              Youthforindia.org uses Trackers to deliver personalized marketing
              content based on User behavior and to operate, serve and track
              ads.
            </p>

            <el-collapse
              v-model="activeNames"
              @change="handleChange"
              accordion
              class="mt-3"
            >
              <el-collapse-item title="" name="1">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Advertising
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <p class="text-787878 px-2">
                    This type of service allows User Data to be utilized for
                    advertising communication purposes. These communications are
                    displayed in the form of banners and other advertisements on
                    youthforindia.org, possibly based on User interests. This
                    does not mean that all Personal Data are used for this
                    purpose. Information and conditions of use are shown below.
                    Some of the services listed below may use Trackers to
                    identify Users or they may use the behavioral retargeting
                    technique, i.e. displaying ads tailored to the User’s
                    interests and behavior, including those detected outside
                    youthforindia.org. For more information, please check the
                    privacy policies of the relevant services. Services of this
                    kind usually offer the possibility to opt out of such
                    tracking. In addition to any opt-out feature offered by any
                    of the services below, Users may learn more on how to
                    generally opt out of interest-based advertising within the
                    dedicated section "How to opt-out of interest-based
                    advertising" in this document.
                  </p>
                  <div class="mt-2">
                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Facebook Audience Network (Facebook, Inc.)
                    </h2>
                    <p class="text-787878 px-2 mt-1">
                      Facebook Audience Network is an advertising service
                      provided by Facebook, Inc. In order to understand
                      Facebook's use of Data, consult Facebook's data policy.
                      Youthforindia.org may use identifiers for mobile devices
                      (including Android Advertising ID or Advertising
                      Identifier for iOS, respectively) and technologies similar
                      to cookies to run the Facebook Audience Network service.
                      One of the ways Audience Network shows ads is by using the
                      User's ad preferences. The User can control this in the
                      Facebook ad settings. Users may opt-out of certain
                      Audience Network targeting through applicable device
                      settings, such as the device advertising settings for
                      mobile phones or by following the instructions in other
                      Audience Network related sections of this privacy policy,
                      if available.

                      <br />
                      Personal Data processed: Cookies, unique device
                      identifiers for advertising (Google Advertiser ID or IDFA,
                      for example) and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.

                      <br />
                      Facebook Lookalike Audience (Facebook, Inc.)
                      <br />
                      Facebook Lookalike Audience is an advertising and
                      behavioral targeting service provided by Facebook, Inc.
                      that uses Data collected through Facebook Custom Audience
                      in order to display ads to Users with similar behavior to
                      Users who are already in a Custom Audience list on the
                      base of their past use of youthforindia.org or engagement
                      with relevant content across the Facebook apps and
                      services.
                      <br />
                      On the base of these Data, personalized ads will be shown
                      to Users suggested by Facebook Lookalike Audience. Users
                      can opt out of Facebook's use of cookies for ads
                      personalization by visiting this opt-out page.
                      <br />
                      Personal Data processed: Cookies and Usage Data.
                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>

                    <h2 class="font-semibold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">_fbp: 3 months</li>
                    </ul>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="" name="2" class="mt-2">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Managing contacts and sending messages
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <p class="text-787878 px-2">
                    This type of service makes it possible to manage a database
                    of email contacts, phone contacts or any other contact
                    information to communicate with the User. These services may
                    also collect data concerning the date and time when the
                    message was viewed by the User, as well as when the User
                    interacted with it, such as by clicking on links included in
                    the message.
                  </p>
                  <div class="mt-2">
                    <h2 class="text-black font-semibold text-lgs">
                      Sendgrid (Sendgrid)
                    </h2>
                    <p class="text-787878 px-2">
                      Sendgrid is an email address management and message
                      sending service provide by Sendgrid Inc.<br />
                      Personal data processed address company name, Cookies,
                      country, date of birth and first name<br />
                      Place of processing United States - Privacy policy
                    </p>
                  </div>
                </div>
              </el-collapse-item>
              <el-collapse-item title="" name="3" class="mt-2">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Remarketing and behavioral targeting
                  </h2></template
                >
                <div class="lg:pt-7 px-5 pt-4">
                  <p class="text-787878 px-2">
                    This type of service allows youthforindia.org and its
                    partners to inform, optimize and serve advertising based on
                    past use of youthforindia.org by the User.
                    <br />This activity is facilitated by tracking Usage Data
                    and by using Trackers to collect information which is then
                    transferred to the partners that manage the remarketing and
                    behavioral targeting activity. <br />Some services offer a
                    remarketing option based on email address lists. <br />In
                    addition to any opt-out feature provided by any of the
                    services below, Users may opt out by visiting the Network
                    Advertising Initiative opt-out page.
                  </p>
                  <h2 class="font-bold text-black text-lgs mt-3">
                    Users may also opt-out of certain advertising features
                    through applicable device settings, such as the device
                    advertising settings for mobile phones or ads settings in
                    general.
                  </h2>
                  <br />
                  <h2 class="font-bold text-black text-lgs mt-3">
                    Twitter Remarketing (Twitter, Inc.)
                  </h2>
                  <p class="text-787878 px-2">
                    Twitter Remarketing is a remarketing and behavioral
                    targeting service provided by Twitter, Inc. that connects
                    the activity of youthforindia.org with the Twitter
                    advertising network.
                    <br />Personal Data processed: Cookies and Usage Data.<br />
                    Place of processing: United States – Privacy Policy – Opt
                    Out.
                  </p>

                  <h2 class="font-bold text-black text-lgs mt-3">
                    Storage duration:
                  </h2>
                  <ul>
                    <li class="lig">personalization_id: 2 years</li>
                  </ul>

                  <div class="mt-2">
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Twitter Tailored Audiences (Twitter, Inc.)
                    </h2>

                    <p class="text-787878 px-2">
                      Twitter Tailored Audiences is a remarketing and behavioral
                      targeting service provided by Twitter, Inc. that connects
                      the activity of youthforindia.org with the Twitter
                      advertising network. Users can opt out from interest-based
                      ads by going to: Personalization and Data.

                      <br />Personal Data processed: Cookies and email address.
                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">personalization_id: 2 years</li>
                    </ul>
                  </div>

                  <div class="mt-2">
                    <h2 class="font-bold text-black text-lgs mt-3">
                      LinkedIn Website Retargeting (LinkedIn Corporation)
                    </h2>

                    <p class="text-787878 px-2">
                      LinkedIn Website Retargeting is a remarketing and
                      behavioral targeting service provided by LinkedIn
                      Corporation that connects the activity of
                      youthforindia.org with the LinkedIn advertising network.

                      <br />Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">AnalyticsSyncHistory: 1 month</li>
                      <li class="lig">JSESSIONID: duration of the session</li>
                      <li class="lig">UserMatchHistory: 1 month</li>

                      <li class="lig">bcookie: 1 year</li>
                      <li class="lig">bscookie: 1 year</li>
                      <li class="lig">lang: duration of the session</li>

                      <li class="lig">lidc: 1 day</li>
                      <li class="lig">lissc: 1 year</li>
                      <li class="lig">lms_ads: 1 month</li>
                      <li class="lig">lms_analytics: 1 month</li>
                    </ul>
                  </div>

                  <div class="mt-2">
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Google Ads Remarketing (Google LLC)
                    </h2>

                    <p class="text-787878 px-2">
                      Google Ads Remarketing is a remarketing and behavioral
                      targeting service provided by Google LLC that connects the
                      activity of youthforindia.org with the Google Ads
                      advertising network and the DoubleClick Cookie. Users can
                      opt out of Google's use of cookies for ads personalization
                      by visiting Google's Ads Settings.

                      <br />Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">AID: 2 years</li>
                      <li class="lig">ANID: 2 years</li>
                      <li class="lig">Conversion: 3 months</li>

                      <li class="lig">DSID: 14 days</li>
                      <li class="lig">FCNEC: 1 year</li>
                      <li class="lig">FLC: 10 seconds</li>

                      <li class="lig">FPAU: 3 months</li>
                      <li class="lig">FPGCLAW: 3 months</li>
                      <li class="lig">FPGCLDC: 3 months</li>
                      <li class="lig">FPGCLGB: 3 months</li>

                      <li class="lig">IDE: 2 years</li>
                      <li class="lig">NID: 6 months</li>
                      <li class="lig">RUL: 1 year</li>
                      <li class="lig">TAID: 14 days</li>

                      <li class="lig">__gads: 2 years</li>
                      <li class="lig">__gsas: 2 years</li>
                      <li class="lig">_gac_: 3 months</li>
                      <li class="lig">_gac_gb_: 3 months</li>

                      <li class="lig">_gcl_au: 3 months</li>
                      <li class="lig">_gcl_aw: 3 months</li>
                      <li class="lig">_gcl_dc: 3 months</li>
                      <li class="lig">_gcl_gb: 3 months</li>

                      <li class="lig">_gcl_gf: 3 months</li>
                      <li class="lig">_gcl_ha: 3 months</li>
                      <li class="lig">id: 2 years</li>
                      <li class="lig">test_cookie: 15 minutes</li>
                    </ul>
                  </div>

                  <div class="mt-2">
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Facebook Remarketing (Facebook, Inc.)
                    </h2>

                    <p class="text-787878 px-2">
                      Facebook Remarketing is a remarketing and behavioral
                      targeting service provided by Facebook, Inc. that connects
                      the activity of youthforindia.org with the Facebook
                      advertising network.

                      <br />Personal Data processed: Cookies and Usage Data.

                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">_fbp: 3 months</li>
                    </ul>
                  </div>

                  <div class="mt-2">
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Facebook Custom Audience (Facebook, Inc.)
                    </h2>

                    <p class="text-787878 px-2">
                      Facebook Custom Audience is a remarketing and behavioral
                      targeting service provided by Facebook, Inc. that connects
                      the activity of youthforindia.org with the Facebook
                      advertising network. Users can opt out of Facebook's use
                      of cookies for ads personalization by visiting this
                      opt-out page.

                      <br />Personal Data processed: Cookies and email address.

                      <br />
                      Place of processing: United States – Privacy Policy – Opt
                      Out.
                    </p>
                    <h2 class="font-bold text-black text-lgs mt-3">
                      Storage duration:
                    </h2>
                    <ul>
                      <li class="lig">_fbp: 3 months</li>
                    </ul>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="mt-5">
          <h3 class="text-black fsize14 font-semibold">
            How to manage preferences and provide or withdraw consent
          </h3>
          <p class="text-gray font--normal fsize14">
            There are various ways to manage Tracker related preferences and to
            provide and withdraw consent, where relevant: Users can manage
            preferences related to Trackers from directly within their own
            device settings, for example, by preventing the use or storage of
            Trackers. Additionally, whenever the use of Trackers is based on
            consent, Users can provide or withdraw such consent by setting their
            preferences within the cookie notice or by updating such preferences
            accordingly via the relevant consent-preferences widget, if
            available. It is also possible, via relevant browser or device
            features, to delete previously stored Trackers, including those used
            to remember the User’s initial consent. Other Trackers in the
            browser’s local memory may be cleared by deleting the browsing
            history. With regard to any third-party Trackers, Users can manage
            their preferences and withdraw their consent via the related opt-out
            link (where provided), by using the means indicated in the third
            party's privacy policy, or by contacting the third party.
          </p>
        </div>
        <div class="mt-5">
          <h3 class="text-black fsize14 font-semibold">
            Locating Tracker Settings
          </h3>
          <p class="text-gray font--normal fsize14">
            Users can, for example, find information about how to manage Cookies
            in the most commonly used browsers at the following addresses:
          </p>
          <ul>
            <li class="lig">Google Chrome</li>
            <li class="lig">Mozilla Firefox</li>
            <li class="lig">Apple Safari</li>
            <li class="lig">Microsoft Internet Explorer</li>
            <li class="lig">Microsoft Edge</li>
            <li class="lig">Brave</li>
            <li class="lig">Opera</li>
          </ul>
          <p class="text-gray font--normal mt-2 fsize14">
            Users may also manage certain categories of Trackers used on mobile
            apps by opting out through relevant device settings such as the
            device advertising settings for mobile devices, or tracking settings
            in general (Users may open the device settings and look for the
            relevant setting).
          </p>
          <div class="mt-3">
            <h3 class="text-black fsize14 font-semibold">
              How to opt out of interest-based advertising
            </h3>
            <p class="text-gray font--normal fsize14">
              Notwithstanding the above, Users may follow the instructions
              provided by YourOnlineChoices (EU), the Network Advertising
              Initiative (US) and the Digital Advertising Alliance (US), DAAC
              (Canada), DDAI (Japan) or other similar services. Such initiatives
              allow Users to select their tracking preferences for most of the
              advertising tools. The Owner thus recommends that Users make use
              of these resources in addition to the information provided in this
              document.<br />
              The Digital Advertising Alliance offers an application called
              AppChoices that helps Users to control interest-based advertising
              on mobile apps. Consequences of denying consent Users are free to
              decide whether or not to grant consent. However, please note that
              Trackers help youthforindia.org to provide a better experience and
              advanced functionalities to Users (in line with the purposes
              outlined in this document). Therefore, in the absence of the
              User's consent, the Owner may be unable to provide related
              features.
            </p>
          </div>
          <div class="mt-3">
            <h3 class="text-black fsize14 font-semibold">
              Owner and Data Controller
            </h3>
            <p class="text-gray font-normal fsize14">
              World Trade Centre, No. 35, Ground Floor The Arcade, Cuffe Parade,
              Mumbai, Maharashtra 400005
              <br />
              <span class="text-black"> Owner contact email:</span>
              contactus@youthforindia.org<br />
              Since the use of third-party Trackers through youthforindia.org
              cannot be fully controlled by the Owner, any specific references
              to third-party Trackers are to be considered indicative. In order
              to obtain complete information, Users are kindly requested to
              consult the privacy policies of the respective third-party
              services listed in this document. Given the objective complexity
              surrounding tracking technologies, Users are encouraged to contact
              the Owner should they wish to receive any further information on
              the use of such technologies by youthforindia.org.
            </p>
            <el-collapse
              v-model="activeNames"
              @change="handleChange"
              accordion
              class="my-4"
            >
              <el-collapse-item title="" name="1">
                <template slot="title"
                  ><h2 class="text-primary lg:pl-4 pl-2 font-bold py-4 fsize14">
                    Definitions and legal references
                  </h2></template
                >
                <div class="lg:pt-7 pt-4">
                  <div>
                    <h2 class="text-lgs text-black font-semibold">
                      Personal Data (or Data)
                    </h2>
                    <p class="text-787878 px-2">
                      Any information that directly, indirectly, or in
                      connection with other information — including a personal
                      identification number — allows for the identification or
                      identifiability of a natural person.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      Usage Data
                    </h2>
                    <p class="text-787878 px-2">
                      Information collected automatically through
                      youthforindia.org (or third-party services employed in
                      youthforindia.org), which can include: the IP addresses or
                      domain names of the computers utilized by the Users who
                      use youthforindia.org, the URI addresses (Uniform Resource
                      Identifier), the time of the request, the method utilized
                      to submit the request to the server, the size of the file
                      received in response, the numerical code indicating the
                      status of the server's answer (successful outcome, error,
                      etc.), the country of origin, the features of the browser
                      and the operating system utilized by the User, the various
                      time details per visit (e.g., the time spent on each page
                      within the Application) and the details about the path
                      followed within the Application with special reference to
                      the sequence of pages visited, and other parameters about
                      the device operating system and/or the User's IT
                      environment.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">User</h2>
                    <p class="text-787878 px-2">
                      The individual using youthforindia.org who, unless
                      otherwise specified, coincides with the Data Subject.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      Data Subject
                    </h2>
                    <p class="text-787878 px-2">
                      The natural person to whom the Personal Data refers.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      Data Processor (or Data Supervisor)
                    </h2>
                    <p class="text-787878 px-2">
                      The natural or legal person, public authority, agency or
                      other body which processes Personal Data on behalf of the
                      Controller, as described in this privacy policy.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      Data Controller (or Owner)
                    </h2>
                    <p class="text-787878 px-2">
                      The natural or legal person, public authority, agency or
                      other body which, alone or jointly with others, determines
                      the purposes and means of the processing of Personal Data,
                      including the security measures concerning the operation
                      and use of youthforindia.org. The Data Controller, unless
                      otherwise specified, is the Owner of youthforindia.org.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      youthforindia.org (or this Application)
                    </h2>
                    <p class="text-787878 px-2">
                      The means by which the Personal Data of the User is
                      collected and processed.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">Service</h2>
                    <p class="text-787878 px-2">
                      The service provided by youthforindia.org as described in
                      the relative terms (if available) and on this
                      site/application.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      European Union (or EU)
                    </h2>
                    <p class="text-787878 px-2">
                      Unless otherwise specified, all references made within
                      this document to the European Union include all current
                      member states to the European Union and the European
                      Economic Area.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">Cookie</h2>
                    <p class="text-787878 px-2">
                      Cookies are Trackers consisting of small sets of data
                      stored in the User's browser.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">Tracker</h2>
                    <p class="text-787878 px-2">
                      Tracker indicates any technology - e.g Cookies, unique
                      identifiers, web beacons, embedded scripts, e-tags and
                      fingerprinting - that enables the tracking of Users, for
                      example by accessing or storing information on the User’s
                      device.
                    </p>
                  </div>
                  <div class="mt-2">
                    <h2 class="text-lgs text-black font-semibold">
                      Legal information
                    </h2>
                    <p class="text-787878 px-2">
                      This privacy statement has been prepared based on
                      provisions of multiple legislations, including Art. 13/14
                      of Regulation (EU) 2016/679 (General Data Protection
                      Regulation).
                      <br />
                      This privacy policy relates solely to youthforindia.org,
                      if not stated otherwise within this document.
                    </p>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <p class="text-gray font-normal fsize14">
              Latest update: March 07, 2022
              <br />
              iubenda hosts this content and only collects the Personal Data
              strictly necessary for it to be provided.
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <router-view />
    <div
      class="fixed z-40 bottom-12 right-2 border-2 bg-white bokj mobile"
      v-if="name"
    >
      <!-- -->
      <div class="px-8 py-3 relative" v-if="cookies">
        <p class="text-dark fsize14 p-2">
          This website uses cookies to ensure you<br />
          get the best experience on our website.
        </p>
        <div class="flex gap-2">
          <b-button
            variant="primary"
            class="py-2 px-8 font-normal"
            @click="acceptcookies"
            >Accept</b-button
          >
          <b-button
            variant="secondary"
            class="py-2 px-6 font-normal"
            @click="centerDialogVisiblec = true"
            >Learn More</b-button
          >
        </div>
        <div class="absolute top-2 right-3 cursor-pointer">
          <feather-icon
            icon="XIcon"
            svgClasses="w-4 h-4"
            class="text-secondary"
            @click="name = !name"
          />
        </div>
      </div>
    </div>

    <div class="fixed bot-position w-full z-index100">
      <marquee
        class="bg-secondary text-white py-1 font-semibold mb-0"
        scrollamount="7"
      >
        <div class="flex items-center gap-4">
          <p class="fsize14 p-0">
            SBI Youth For India Fellowship 2025-26 Applications Are Now Open!
            Apply Here-
            <a
              href="https://change.youthforindia.org/application-process/apply/2453"
              target="_blank"
              >https://change.youthforindia.org</a
            >
          </p>
          <!-- <p>
            <span> 1.</span> Request for proposal from Digital Marketing
            Agencies -
            <a
              href="http://tinyurl.com/YFIDigiMark"
              target="_blank"
              class="underline"
            >
              tinyurl.com/YFIDigiMark
            </a>
          </p>

          <p>
            <span> 2.</span> Request for proposal from Public Relations Agencies
            -
            <a
              href="http://tinyurl.com/YFIPRRFP"
              target="_blank"
              class="underline"
            >
              tinyurl.com/YFIPRRFP
            </a>
          </p>

          <p>
            <span> 3.</span> Request for proposal from Offline Youth Marketing
            Agencies -
            <a
              href="http://tinyurl.com/YFIOffMark"
              target="_blank"
              class="underline"
            >
              tinyurl.com/YFIOffMark
            </a>
          </p> -->
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      cookies: true,
      name: true,
      centerDialogVisiblec: false,
      activeNames: "",
    };
  },
  computed: {
    ...mapState("fellowfrofile", {
      emaildetails: "emaildetails",
    }),
  },
  metaInfo() {
    return {
      title: "SBI | YOUTH FOR INDIA",
      meta: [
        {
          name: "description",
          content: "SBI | YOUTH FOR INDIA",
        },
      ],
    };
  },
  methods: {
    handleChange() {},
    acceptcookies() {
      const data = new Date().getTime();
      let expiery = data + 60 * 60 * 24 * 1000 * 7;
      document.cookie = `name="https://youthforindia.org"; expires=${expiery}`;

      let value = document.cookie;

      let cookiesvalue = value
        .split(";")
        .map((cookies) => cookies.split("="))
        .reduce(
          (accum, [key, value]) => ({
            ...accum,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (cookiesvalue.name) {
        this.cookies = false;
      }
    },
  },
  async mounted() {
    let value = document.cookie;

    let cookiesvalue = value
      .split(";")
      .map((cookies) => cookies.split("="))
      .reduce(
        (accum, [key, value]) => ({
          ...accum,
          [key.trim()]: decodeURIComponent(value),
        }),
        {}
      );
    if (cookiesvalue.name) {
      this.cookies = false;
    }
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.bokj {
  border-radius: 10px 0 0 10px;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
