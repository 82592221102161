import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=061beae8"
import script from "./Card.vue?vue&type=script&lang=js"
export * from "./Card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('061beae8')) {
      api.createRecord('061beae8', component.options)
    } else {
      api.reload('061beae8', component.options)
    }
    module.hot.accept("./Card.vue?vue&type=template&id=061beae8", function () {
      api.rerender('061beae8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Cards/Card.vue"
export default component.exports